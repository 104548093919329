<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back">

		<app-content-head-actions-item v-if="!isNew && item.status && hasContent" icon="preview" text="View" :url="item.url" />
		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/pages/access', 'read')">

			<app-input-text label="Name" :maxlength="32" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-toggle label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.page.types" />
			<app-input-text v-if="!isNew && hasContent" label="URL" v-model="model.slug" :validation="$v.model.slug" />
			<app-input-text v-if="!hasContent" label="URL" :maxlength="255" placeholder="Enter URL..." v-model="model.redirect" :validation="$v.model.redirect" />
			<app-input-text label="Description" :autogrow="true" :maxlength="250" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-toggle v-if="!isNew" label="Online" v-model="model.status" :validation="$v.model.status" />

		</app-content-box>

		<app-content-section icon="blocks" v-if="!isNew && hasContent" route="Convention.Pages.Edit.Blocks" :params="{ page: model.id }" title="Content blocks" description="Manage the content of this page." :count="0" />
		
	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/pages/access', 'read'),
			model: {
				id: false,
				name: '',
				description: '',
				redirect: '',
				status: 0,
				type: 4,
				slug: ''
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			}
		}
	},

	computed: {

		hasContent: function() {

			return this.model.type === this.$constants.page.type.content
		
		}

	}

}

</script>

<style scoped>

</style>